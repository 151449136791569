import React, { useState, useEffect, useMemo } from "react";
import  { useTable }  from "react-table";
//import 'bootstrap/dist/css/bootstrap.css';
import { API } from 'aws-amplify';

import { listReportStatistics } from "../graphql/queries";

import {
    Flex,
    //Heading,
    //Text,
    View,
    //withAuthenticator,
  } from '@aws-amplify/ui-react';

//class Greeting4 extends Component {
const ReportsStatisticsList = (props) => {
  //doesn't work need to use this in a function to use state
  //const [fullname, setFullname] = useState(0);
  const [sportId, setSportId] = useState(0);
  const [reportsStatistics, setReportStatistics] = useState([]);
  const [lastUpdated, setLastUpdate] = useState("n/a");

  useEffect(() => {
    // no dependencies array,
    // Runs AFTER EVERY render
    //console.log("Rendering Greeting4 class...");
    //console.log("Current league: " + props.league);
    //fetchTeams();
  })

  //renders only on load
  useEffect(() => {  
    //console.log("Rendering ReportsStatisticsList class for first time...");
    if (props.league !== "") {
      fetchReportsStatistics();
    }
  }, []); 

  function getSportId() {
    switch (props.league) {
      case "MLB":
        return 0
      case "NBA":
        return 1
      case "NFL":
        return 4
      default:
        return -1;        
    }
  }

  function formatUTC(lastUpdated) {
  
    console.log("Last updated: ", lastUpdated);
    const date = new Date(lastUpdated);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };
    const localTime = date.toLocaleString('en-US', options);
    console.log(localTime);
/*
    const date = new Date(lastUpdated);
    const currentTimezoneOffset = date.getTimezoneOffset() * 60 * 1000;
    const currentTimezoneDate = new Date(date.getTime() + currentTimezoneOffset);
    
    console.log("offset: ", date.getTimezoneOffset());
    console.log(lastUpdated);
    console.log(currentTimezoneDate);
*/
    return localTime; //currentTimezoneDate.toLocaleString();
    //date.toLocaleTimeString();
    //currentTimezoneDate.toLocaleTimeString() //.toLocaleString();//.toDateString();
  }
  async function fetchReportsStatistics() {

    //console.log("Fetching report statistics from table...");

    const apiData = await API.graphql({ query: listReportStatistics, variables: { limit: 2000} });
    var reportStatisticsFromAPI = apiData.data.listReportStatistics.items;
    var filteredReportStatisticsFromAPI = [];
    var lastUpdated = "n/a";

    let sportId = getSportId();
   
    reportStatisticsFromAPI = reportStatisticsFromAPI.filter(team => team._deleted !== true && team.SportId === sportId);
          
    var itemCount = 0;
     //}
    //teamsFromAPI = teamsFromAPI.filter(team => team._deleted !== true);
    
    reportStatisticsFromAPI.forEach(
      
      function(d){
        //forEachData += '<li>' + d.name + '</li>'

       if (lastUpdated.indexOf('n/a') !== -1) {
          lastUpdated = formatUTC(d.LastUpdated);
          setLastUpdate(lastUpdated);
       }
       
       //console.log(d);//d.lastUpdated);

        d.ReportName = d.ReportName.replace('BetType:','');
        d.ReportName = d.ReportName.replace('Location:','');
        d.ReportName = d.ReportName.replace('OddType:','');

        d.ReportName = d.ReportName.replace('-->0','');
        d.ReportName = d.ReportName.replace('-->150','');
        d.ReportName = d.ReportName.replace('-->200','');
        d.ReportName = d.ReportName.replace('-->300','');
        d.ReportName = d.ReportName.replace('-->500','');

        d.ReportName = d.ReportName.replace('-->-150','');
        d.ReportName = d.ReportName.replace('-->-200','');
        d.ReportName = d.ReportName.replace('-->-300','');
        d.ReportName = d.ReportName.replace('-->-500','');

        var isBoth = false;

        if (d.MoneyLineFilter > 0) {
          d.MoneyLineFilter = ">= +" + d.MoneyLineFilter; 
          
          if (d.ReportName.indexOf('Favorite+Underdog') !== -1) {
            isBoth = true;
          }         
        }
        else if (d.MoneyLineFilter < 0) {
          d.MoneyLineFilter = "<= " + d.MoneyLineFilter;

          if (d.ReportName.indexOf('Favorite+Underdog') !== -1) {
            isBoth = true;
          }
        }
        /*
        else if ((d.MoneyLineFilter !== 0) && (d.ReportName.indexOf('Favorite+Underdog') !== -1)) { //} && ()) {
          isBoth = true; //don't want these
        } 
        */     
        //console.log(d.ReportName);

        //need to check if filter
        if (checkFilters(d)) { 
          itemCount = itemCount + 1;       
          d.ItemId = itemCount;//1;//count; 
          //if (d.ReportName.indexOf('Net Win Pnl $') !== -1) {
          if (!isBoth) {
            filteredReportStatisticsFromAPI.push(d);
          }
          //}
        }
       }       
    )

    //now we need to sort
    if (props.sortBy.indexOf('Net Win Pnl $') !== -1) {
      if (props.sortByType.indexOf('Descending') !== -1) {
        filteredReportStatisticsFromAPI = filteredReportStatisticsFromAPI.sort((a, b) => (a.NetWinPnl < b.NetWinPnl) ? 1 : -1);
      }
      else {
        //console.log("sort ascending....");
        filteredReportStatisticsFromAPI = filteredReportStatisticsFromAPI.sort((a, b) => (a.NetWinPnl < b.NetWinPnl) ? -1 : 1);
      }
    }
    else if (props.sortBy.indexOf('Net Loss Pnl $') !== -1) {
      if (props.sortByType.indexOf('Descending') !== -1) {
        filteredReportStatisticsFromAPI = filteredReportStatisticsFromAPI.sort((a, b) => (a.NetLossPnl < b.NetLossPnl) ? 1 : -1);
      }
      else {
        //console.log("sort ascending....");
        filteredReportStatisticsFromAPI = filteredReportStatisticsFromAPI.sort((a, b) => (a.NetLossPnl < b.NetLossPnl) ? -1 : 1);
      }
    }
    else if (props.sortBy.indexOf('Avg Win Pnl $') !== -1) {
      if (props.sortByType.indexOf('Descending') !== -1) {
        filteredReportStatisticsFromAPI = filteredReportStatisticsFromAPI.sort((a, b) => (a.AvgWinPnl < b.AvgWinPnl) ? 1 : -1);
      }
      else {
        //console.log("sort ascending....");
        filteredReportStatisticsFromAPI = filteredReportStatisticsFromAPI.sort((a, b) => (a.AvgWinPnl < b.AvgWinPnl) ? -1 : 1);
      }
    }
    else if (props.sortBy.indexOf('Avg Loss Pnl $') !== -1) {
      if (props.sortByType.indexOf('Descending') !== -1) {
        filteredReportStatisticsFromAPI = filteredReportStatisticsFromAPI.sort((a, b) => (a.AvgLossPnl < b.AvgLossPnl) ? 1 : -1);
      }
      else {
        //console.log("sort ascending....");
        filteredReportStatisticsFromAPI = filteredReportStatisticsFromAPI.sort((a, b) => (a.AvgLossPnl < b.AvgLossPnl) ? -1 : 1);
      }
    }
    else if (props.sortBy.indexOf('Win %') !== -1) {
      if (props.sortByType.indexOf('Descending') !== -1) {
        filteredReportStatisticsFromAPI = filteredReportStatisticsFromAPI.sort((a, b) => (a.WinPercentage < b.WinPercentage) ? 1 : -1);
      }
      else {
        //console.log("sort ascending....");
        filteredReportStatisticsFromAPI = filteredReportStatisticsFromAPI.sort((a, b) => (a.WinPercentage < b.WinPercentage) ? -1 : 1);
      }
    }
    else if (props.sortBy.indexOf('Loss %') !== -1) {
      if (props.sortByType.indexOf('Descending') !== -1) {
        filteredReportStatisticsFromAPI = filteredReportStatisticsFromAPI.sort((a, b) => (a.LossPercentage < b.LossPercentage) ? 1 : -1);
      }
      else {
        //console.log("sort ascending....");
        filteredReportStatisticsFromAPI = filteredReportStatisticsFromAPI.sort((a, b) => (a.LossPercentage < b.LossPercentage) ? -1 : 1);
      }
    }

    //re number
    let count = 0;

    filteredReportStatisticsFromAPI.forEach(
      function(d){   
        count = count + 1;    
        d.ItemId = count; 
       }       
    )

    //filteredReportStatisticsFromAPI = filteredReportStatisticsFromAPI.sort((a, b) => (a.NetWinPnl < b.NetWinPnl) ? 1 : -1);
    /*
    console.log("Bet Type Filter: ", props.betTypeFilter);
    console.log("Money Line Filter: ", props.moneyLineFilter);
    console.log("Filtered length: ", filteredReportStatisticsFromAPI.length);
    
    if ((props.betTypeFilter === "All") && (props.moneyLineFilter !== "0")) {
      console.log("got here 1");
    }
    else if ((props.betTypeFilter !== "All") && (props.moneyLineFilter === "0")) {
      console.log("got here 2");
    }
    else if ((props.betTypeFilter !== "All") && (props.moneyLineFilter !== "0")) {
      console.log("got here 3");
    }
    */

    setReportStatistics(filteredReportStatisticsFromAPI);      
  };


  function checkFilters(d) {

    if ((props.betTypeFilter === "All") && (props.moneyLineFilter !== "0")) {
      
      if (d.MoneyLineFilter.toString().indexOf(props.moneyLineFilter) !== -1) {
        //console.log("ML Filter: ", d.MoneyLineFilter.toString());
        return true;
      }

      return false;
    }
    else if ((props.betTypeFilter !== "All") && (props.moneyLineFilter === "0")) {
      
      if (d.ReportName.indexOf(props.betTypeFilter) !== -1) {
        //console.log("added from 2...");
        return true;
      }

      return false;
    }
    else if ((props.betTypeFilter !== "All") && (props.moneyLineFilter !== "0")) {
      
      if ((d.ReportName.indexOf(props.betTypeFilter) !== -1) &&  (d.MoneyLineFilter.toString().indexOf(props.moneyLineFilter) !== -1)) {
        //console.log("added from 3...");
        return true;
      }
      
      return false;
    }

    return true;
  };

  /*
  function stateChange(f){
   // console.log("This is the target: " + f.target.value )
    
    //setFullname(f.target.value);
    setSportId(sportId + 1);

    //console.log("Current sport id: " + sportId);
  }
*/

  const tableColumns = [
    {   
        Header : "#",
        accessor : "ItemId"
    },
    {   
        Header : "Bet Type",
        accessor : "ReportName"
    },
    {   
      Header : "Money Line Filter",
      accessor : "MoneyLineFilter"
    },
    {   
        Header : "Games",
        accessor : "Games"
    },
    {   
      Header : "Wins",
      accessor : "Wins"
    },
    {   
      Header : "Losses",
      accessor : "Losses"
    },
    {   
        Header : "Win %",
        accessor : "WinPercentage"
    },    
    {   
      Header : "Loss %",
      accessor : "LossPercentage"
    },
    {   
      Header : "Avg Win Pnl $",
      accessor : "AvgWinPnl"
    },
    {   
      Header : "Avg Loss Pnl $",
      accessor : "AvgLossPnl"
    },
    {   
      Header : "Net Win Pnl $",
      accessor : "NetWinPnl"
    },
    {   
      Header : "Net Loss Pnl $",
      accessor : "NetLossPnl"
    },
    {   
      Header : "Recent Record",
      accessor : "RecentRecord"
    },
  ];
  
  //use memo doesn't recalc on every re render
  const columns = useMemo(() => tableColumns, [])
  //const data = useMemo(() => streaks, [])
  const data = reportsStatistics
  
  const tableInstance = useTable({
      columns,
      data
  })
  
  const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow
  } = tableInstance


  return (  
  
    <div>
   
      <Flex           
        direction="row"
        justifyContent="center"
        alignItems="center"        
      >
      <br></br>
      * Last Updated: {lastUpdated}
      </Flex>      
      <Flex           
        direction="row"
        justifyContent="center"
        alignItems="center"       
      >
      <div class="table-container betting-results-table-container">
        <table {...getTableProps()} className="styled-table2 bet-results-styled-table">
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()} className="red">
                        {headerGroup.headers.map((column) => {
                            if ((column.Header.indexOf("Bet Type") === -1)) { //} && (column.Header.indexOf("Details") === -1)) {
                              if ((column.Header.indexOf("$") !== -1) || (column.Header.indexOf("Money Line") !== -1)) { //} && (column.Header.indexOf("Details") === -1)) {
                                return <th id="tdNotBetType" {...column.getHeaderProps()}>{column.render('Header')}</th>
                              }
                              else {
                                return <th id="tdCenter" {...column.getHeaderProps()}>{column.render('Header')}</th>
                              }
                            }
                            else {
                                return <th id="tdBetType" {...column.getHeaderProps()}>{column.render('Header')}</th>
                            }
                            
                        })}                
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {

                                if ((cell.column.Header.indexOf("Bet Type") === -1)) { //} && (cell.column.Header.indexOf("Details") === -1)) {
                                  if ((cell.column.Header.indexOf("$")!== -1) || (cell.column.Header.indexOf("Money Line")!== -1)) {
                                    return <td id="tdNotBetType" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                  }
                                  else {
                                    return <td id="tdCenter" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                  }
                                }
                                else {
                                    return <td id="tdBetType" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                }                            
                            })}
                        </tr>
                    )
                })}
                
            </tbody>
        </table>
      </div>
      </Flex>
         {/*
          <Flex           
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
            * Note: Bet size max is $100 (example: If Money Line bet and line is -500 then bet would be $100 with the profit of $20 if wins.).  Max profit for a bet is $100 (example:  If Money Line bet and line is +500 then bet would be for $20 with a profit of $100 if wins.).
            </Flex>          
          */}
    </div>
  );
}

export default ReportsStatisticsList;