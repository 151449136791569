//import React from "react";
import React, { useState, useEffect } from "react";
import {Consumer} from "./Context";

import {
 // Button,
  Flex,
  Heading,
//  Image,
 // Text,
//  TextField,
  View,
  //withAuthenticator,
} from '@aws-amplify/ui-react';

import './Component.css';

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { ScatterChart, Scatter, ResponsiveContainer } from 'recharts';
import { ComposedChart, Legend } from 'recharts';

import createTrend from 'trendline';

const data = [{name: 'Page A', uv: 400, pv: 2400, amt: 2400},
{name: 'Page B', uv: 300, pv: 2400, amt: 2400},
{name: 'Page C', uv: 250, pv: 2400, amt: 2400}];

const dataScatter= [
    { x: 1, y: 200},
    { x: 2, y: 100},
    { x: 3, y: 300},
    { x: 4, y: 250},
    { x: 5, y: 400},
    { x: 6, y: 280},
  ];

  const dataTrendline= [
    { x: 1, y: 130},
    { x: 2, y: 166},
    { x: 3, y: 202},
    { x: 4, y: 238},
    { x: 5, y: 274},
    { x: 6, y: 310},
  ];

  /*
const dataScatter= [
    { x: 100, y: 200, z: 200 },
    { x: 120, y: 100, z: 260 },
    { x: 170, y: 300, z: 400 },
    { x: 140, y: 250, z: 280 },
    { x: 150, y: 400, z: 500 },
    { x: 110, y: 280, z: 200 },
  ];
  */

  const dataCombo = [
    { index: 10000, red: 1643, blue: 790 },
    { index: 1666, red: 182, blue: 42 },
    { index: 625, red: 56, blue: 11 },

    // Calculation of line of best fit is not included in this demo
    { index: 300, redLine: 0 },
    { index: 10000, redLine: 1522 },
    { index: 600, blueLine: 0 },
    { index: 10000, blueLine: 678 },
    
  ];

const renderLineChart = (
  <LineChart width={400} height={400} data={data}>
    <Line type="monotone" dataKey="uv" stroke="#8884d8" />
  </LineChart>
);

const ChartSample = () => {
 
 // const { weightData } = useAPI();
  //const weightData;
  
  /*= [
    { x: 100, y: 200},
    { x: 120, y: 100},
    { x: 170, y: 300},
    { x: 140, y: 250},
    { x: 150, y: 400},
    { x: 110, y: 280},
  ];
*/
/*
  const weights = weightData.map((data) => data.weight);
  const yMax = Math.max(...weights);
  const yMin = Math.min(...weights);
  const timestamps = weightData.map((data) => data.dateTime);
  const xMax = Math.max(...timestamps);
  const xMin = Math.min(...timestamps);

  const trendData = () => {
    const trend = createTrend(weightData, 'dateTime', 'weight');

    return [
      { weight: trend.calcY(xMin), dateTime: xMin },
      { weight: trend.calcY(xMax), dateTime: xMax },
    ];
  };
*/
  const trend = createTrend(dataScatter, 'x', 'y');
 
  console.log("this is the trend: ", trend);

  return (

    <Consumer>
    { context => {
     // const notes = notes; //context.notes;
      
      return (
        <div>  
        <Flex           
              direction="row"              
              alignItems="flex-start"
            >          
        <div className="pageDiv"> 
          <br></br>         
          <Heading className="headerReportLable" level={1}>Chart Samples</Heading>
          <View margin="3rem 0">          
            <Flex           
                direction="row"
                justifyContent="center"
                alignItems="center"
                >
              <LineChart width={600} height={300} data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
              </LineChart>
            </Flex>

            <Flex           
                direction="row"
                justifyContent="center"
                alignItems="center"
                >
              <ResponsiveContainer width="100%" height={400}>
                <ScatterChart
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                }}
                >
                <CartesianGrid />
                <XAxis type="number" dataKey="x" name="stature" unit="cm" />
                <YAxis type="number" dataKey="y" name="weight" unit="kg" />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Scatter name="A school" data={dataScatter} fill="#8884d8" />
                </ScatterChart>
              </ResponsiveContainer>
           </Flex>

           <Flex           
                direction="row"
                justifyContent="center"
                alignItems="center"
                >
                {/*
              <LineChart
                data={dataTrendline}
                margin={{ top: 5, right: 30, bottom: 5, left: -20 }}
                //onMouseLeave={() => setMessage('')}
              >
              */}
              <ComposedChart width={600} height={300} data={dataScatter} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                <XAxis dataKey="x" />
                <YAxis />
                  {/*
                  <XAxis
                    name="Time"
                    type="number"
                    dataKey="dateTime"
                    domain={['dataMin', 'dataMax']}
                  />
                  <YAxis
                    name="Weight"
                    type="number"
                    dataKey="weight"
                    domain={[yMin, yMax]}
                  />
                  <Line type="monotoneX" dataKey="weight" />
            
                  <Line
                    data={trendData()}
                    dataKey="weight"
                    stroke="red"
                    strokeDasharray="3 3"
                  />
                  <Scatter name="A school" data={dataScatter} fill="#8884d8" />
                  <Scatter name="A school" data={dataScatter} dataKey="x" fill="#8884d8" />

                  <Line
                    data={dataTrendline}
                    dataKey="x"
                    stroke="green"
                    strokeDasharray="3 3"
                  />

                   <Line
                    data={dataScatter}
                    dataKey="y"
                    stroke="red"
                    strokeDasharray="3 3"
                  />    
                  */}             
                
                  <Line
                    data={dataTrendline}
                    dataKey="y"
                    stroke="blue"
                    strokeDasharray="3 3"
                  />

                  <Scatter name="red" dataKey="y" fill="red" />

                </ComposedChart>
              </Flex>

              <Flex           
                direction="row"
                justifyContent="center"
                alignItems="center"
                >
                {/*<ResponsiveContainer width="100%" height="100%">*/}
                <ResponsiveContainer width="100%" height={400}>
                  <ComposedChart
                    width={500}
                    height={400}
                    data={dataCombo}
                    margin={{
                      top: 20,
                      right: 80,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                  
                    <CartesianGrid stroke="#f5f5f5" />
                    <Tooltip />
                    <Legend />

                    <XAxis dataKey="index" type="number" label={{ value: 'Index', position: 'insideBottomRight', offset: 0 }} />
                    <YAxis unit="ms" type="number" label={{ value: 'Time', angle: -90, position: 'insideLeft' }} />
                  
                    <Scatter name="red" dataKey="red" fill="red" />
                    <Scatter name="blue" dataKey="blue" fill="blue" />
                    
                    <Line dataKey="blueLine" stroke="blue" dot={false} activeDot={false} legendType="none" />
                    <Line dataKey="redLine" stroke="red" dot={false} activeDot={false} legendType="none" />
                    
                  </ComposedChart>
                </ResponsiveContainer>
            </Flex>
          </View>
        </div>
        </Flex>
        </div>
      );
    }}
    </Consumer>
  );
};

export default ChartSample;