import React, { useState, useEffect, useContext } from "react";
import {Consumer, myFirstContext} from "./Context";
import { Dropdown, Option } from "../pages/oldstuff/Dropdown";
import { useSearchParams } from "react-router-dom";
//import RankingsList from './RankingsList.jsx';

import {
  //Button,
  Flex,
  Heading,
  //Image,
  //Text,
  //TextField,
  //View,
  //withAuthenticator,
} from '@aws-amplify/ui-react';

import StreaksList from "./StreaksList.jsx";
//import { render } from "node-sass";

const Streaks = (props) => {

  const { sport, setSport } = useContext(myFirstContext);

  let [searchParams, setSearchParams] = useSearchParams();

  function handleLeagueSelect(event) {
    //console.log("Changed select box...");
    //console.log("League target value: " + event.target.value); // + this.sportId);
    //setLeagueValue(event.target.value);
    //setLastChangedValue(event.target.value); 
    //alert(this.context.sport);
    
    //alert("this is a test", sport);
  }
  
 
  //function handleRankingTypeSelect(event) {  
    //console.log("Changed select box...");
    //setRankingTypeValue(event.target.value);  
    //setLastChangedValue(event.target.value);  
  //}

  /*
  useEffect(() => {
    // no dependencies array,
    // Runs AFTER EVERY render
    console.log("Rendering Teams class...");
  })
*/
  //rnders only on load
  useEffect(() => {   
    if (searchParams.get("sport")) {
      setLeagueValue(searchParams.get("sport").toUpperCase());
      setLastChangedValue(searchParams.get("sport").toUpperCase());
    }   
  }, []); 

  //how we use state in function
  const [leagueValue, setLeagueValue] = useState("NFL");
  //const [rankingTypeValue, setRankingTypeValue] = useState("Power");

  const [lastChangedValue, setLastChangedValue] = useState("NFL");

  function checkIfGoodLeague() {
    switch (leagueValue) {
    case "MLB":
        return true
    case "NBA":
        return true
    case "NFL":
        return true
    default:
        return false;        
    }
  }

  function BuildStreaksPage() {
    return (
      <>      
        <Heading className="headerReportLable" level={1}>Current Season {leagueValue} Team Streaks</Heading>           
        <StreaksList key={lastChangedValue} league={leagueValue} />  
      </>)
  }

  return (
   
    <Consumer>
    { context => {

      return (                 
        <div className="pageDiv">         
          <br></br>    
          {checkIfGoodLeague() ? 
              (<BuildStreaksPage />) : (
              <Heading className="headerReportLable" level={1}>Whoops select a league from above.</Heading> )              
          }        
        </div>         
      );
    }}
    </Consumer>
  );
};

export default Streaks;