import { Link, useMatch, useResolvedPath, useSearchParams } from "react-router-dom";

import { ListItem } from '@mui/material';
import React from 'react';
import "./sidebar.scss";
import {SidebarData} from "./SidebarData";
function Sidebar() {
  return (
    
    <div className='Sidebar'>  
      <div className="divLogo">        
        <div className="divImageLogo">
          {/*<a href="/"><img className="imageLogo" src="https://img1.wsimg.com/isteam/ip/e4196709-4fdc-472e-ae66-5895cff05ec1/image-JPG.jpg" alt="betlytics"></img></a>*/}
          <a href="/"><img className="imageLogo" src={require('../images/betlytics_icon.png')} alt="betlytics" title="Home"></img></a>                
        </div>      
        <Link to="/" className="site-title"><span class="text-color">bet</span>lytics</Link> 
      </div>     
      <ul className='SidebarList'>
        {SidebarData.map((val,key) => {
          return (
            <li key={key} 
              className='row' 
              onClick={ () => {window.location.pathname = val.link;}}
              id={window.location.pathname == val.link ? "active" : ""}
              title={val.title}
              >            
              <div id='icon'>{val.icon}</div>
              <div id='title'>
                {val.title}
              </div>
            </li>
          );
        })}
      </ul>      
    </div>
  )
}

export default Sidebar;