import React, { useState, useEffect, useMemo } from "react";
//import 'bootstrap/dist/css/bootstrap.css';
import { API } from 'aws-amplify';

import { listStreaksFeeds} from "../graphql/queries";

import {
    Flex,
    View,
  } from '@aws-amplify/ui-react';

//import Streaks from "./Streaks";

// Import React Table
import  { useTable }  from "react-table";
//import "react-table/react-table.css";

import './Component.css';
//import './BasicTable.css';

const StreaksList = (props) => {

const [sportId, setSportId] = useState(0);
const [streaks, setStreaks] = useState([]);
const [lastUpdated, setLastUpdate] = useState("n/a");

//const data = []// = useMemo(() => testData, [])

useEffect(() => {
    // no dependencies array,
    // Runs AFTER EVERY render
    //console.log("Rendering Greeting4 class...");
    //console.log("Current league: " + props.league);
    //fetchTeams();
})

//renders only on load
useEffect(() => {  
    
    if (props.league !== "") {            
      
        const fetchData = async () => {          
            const returnedData = await fetchStreaks(); 
            //data = useMemo(() => returnedData, []);
        }

        fetchData();          
    }
}, []); 

function getSportId() {
    switch (props.league) {
    case "MLB":
        return 0
    case "NBA":
        return 1
    case "NFL":
        return 4
    default:
        return -1;        
    }
}

function formatUTC(lastUpdated) {

    const date = new Date(lastUpdated);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };
    const localTime = date.toLocaleString('en-US', options);
    return localTime;  
}

function myFunction(e) {
    //console.log(testData);
}



async function fetchStreaks() {
  
    const apiData = await API.graphql({ query: listStreaksFeeds, variables: { limit: 2000} });
    //console.log("we got here part2...");
    var streaksFeedsFromAPI = apiData.data.listStreaksFeeds.items;
    var filteredStreaksFeedsFromAPI = [];
    var lastUpdated = "n/a";

    let sportId = getSportId();

    streaksFeedsFromAPI = streaksFeedsFromAPI.filter(team => team._deleted !== true && team.SportId === sportId);
    
    streaksFeedsFromAPI.forEach(
        function(d){
            //console.log(d);
            if (lastUpdated.indexOf('n/a') !== -1) {
                lastUpdated = formatUTC(d.Created);
                setLastUpdate(lastUpdated);
            }
            filteredStreaksFeedsFromAPI.push(d);      
        }        
    )
                
    filteredStreaksFeedsFromAPI = filteredStreaksFeedsFromAPI.sort((a, b) => (a.AbsStreak
        < b.AbsStreak) ? 1 : -1);

    setStreaks(filteredStreaksFeedsFromAPI);      
   
    //console.log(filteredStreaksFeedsFromAPI);

    return filteredStreaksFeedsFromAPI;
};

const tableColumns = [
    {   
        Header : "Streak",
        accessor : "Streak"
    },
    {   
        Header : "Details",
        accessor : "Details"
    },
];

//use memo doesn't recalc on every re render
const columns = useMemo(() => tableColumns, [])
//const data = useMemo(() => streaks, [])
const data = streaks

const tableInstance = useTable({
    columns,
    data
})

const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
} = tableInstance

return (   
  
    <div class="table-responsive">
    
        <Flex           
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
        <br></br>
        * Last Updated: {lastUpdated}
        </Flex>

        <Flex           
        direction="column"
        justifyContent="center"
        alignItems="center"
        >
            <div class="table-container streaks-table-container">
                <table {...getTableProps()} className="styled-table2">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()} className="red">
                                {headerGroup.headers.map((column) => {
                                    if ((column.Header.indexOf("Team Name") === -1) && (column.Header.indexOf("Details") === -1)) {
                                        return <th id="tdCenter" {...column.getHeaderProps()}>{column.render('Header')}</th>
                                    }
                                    else {
                                        return <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                    }
                                    
                                })}                
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {

                                        if ((cell.column.Header.indexOf("Team Name") === -1) && (cell.column.Header.indexOf("Details") === -1)) {
                                            return <td id="tdCenter" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        }
                                        else {
                                            return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        }                            
                                    })}
                                </tr>
                            )
                        })}                
                    </tbody>
                </table>
            </div>
        </Flex>   
    </div>
  );
}

export default StreaksList;