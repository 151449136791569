import React from "react";
import { Image, useTheme, Flex } from "@aws-amplify/ui-react";

export function Header() {
  const { tokens } = useTheme();

  return (
    <Flex           
              direction="row"
              justifyContent="center"
              alignItems="center"
            >    
    <Image
      alt="logo"
      src="https://img1.wsimg.com/isteam/ip/e4196709-4fdc-472e-ae66-5895cff05ec1/image-JPG.jpg"
      padding={tokens.space.medium}
      width={200}
      height={200}
    />
    </Flex>
  );
}
