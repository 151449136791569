/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStreaksFeed = /* GraphQL */ `
  query GetStreaksFeed($id: ID!) {
    getStreaksFeed(id: $id) {
      id
      SportId
      Created
      Details
      IsSummary
      OpponentTeamId
      Streak
      StreakName
      TeamId
      TeamName
      AbsStreak      
    }
  }
`;
export const listStreaksFeeds = /* GraphQL */ `
  query ListStreaksFeeds(
    $filter: ModelStreaksFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStreaksFeeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        SportId
        Created
        Details
        IsSummary
        OpponentTeamId
        Streak
        StreakName
        TeamId
        TeamName
        AbsStreak
      }
      nextToken
    }
  }
`;
export const getCurrentTeamsBetStats = /* GraphQL */ `
  query GetCurrentTeamsBetStats($id: ID!) {
    getCurrentTeamsBetStats(id: $id) {
      id
      SportId
      ActualLosses
      ActualStreak
      ActualTies
      ActualWins
      AwayLosses
      AwayTies
      AwayWins
      HomeLosses
      HomeTies
      HomeWins
      OverUnderAwayLosses
      OverUnderAwayTies
      OverUnderAwayWins
      OverUnderHomeLosses
      OverUnderHomeTies
      OverUnderHomeWins
      OverUnderLosses
      OverUnderStreak
      OverUnderTies
      OverUnderWins
      PointSpreadAwayLosses
      PointSpreadAwayTies
      PointSpreadAwayWins
      PointSpreadHomeLosses
      PointSpreadHomeTies
      PointSpreadHomeWins
      PointSpreadLosses
      PointSpreadLossesOverUnderLosses
      PointSpreadLossesOverUnderWins
      PointSpreadStreak
      PointSpreadTies
      PointSpreadWins
      PointSpreadWinsOverUnderLosses
      PointSpreadWinsOverUnderWins
      TeamId      
    }
  }
`;
export const listCurrentTeamsBetStats = /* GraphQL */ `
  query ListCurrentTeamsBetStats(
    $filter: ModelCurrentTeamsBetStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurrentTeamsBetStats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        SportId
        ActualLosses
        ActualStreak
        ActualTies
        ActualWins
        AwayLosses
        AwayTies
        AwayWins
        HomeLosses
        HomeTies
        HomeWins
        OverUnderAwayLosses
        OverUnderAwayTies
        OverUnderAwayWins
        OverUnderHomeLosses
        OverUnderHomeTies
        OverUnderHomeWins
        OverUnderLosses
        OverUnderStreak
        OverUnderTies
        OverUnderWins
        PointSpreadAwayLosses
        PointSpreadAwayTies
        PointSpreadAwayWins
        PointSpreadHomeLosses
        PointSpreadHomeTies
        PointSpreadHomeWins
        PointSpreadLosses
        PointSpreadLossesOverUnderLosses
        PointSpreadLossesOverUnderWins
        PointSpreadStreak
        PointSpreadTies
        PointSpreadWins
        PointSpreadWinsOverUnderLosses
        PointSpreadWinsOverUnderWins
        TeamId        
      }
      nextToken
    }
  }
`;
export const getCurrentPowerRankings = /* GraphQL */ `
  query GetCurrentPowerRankings($id: ID!) {
    getCurrentPowerRankings(id: $id) {
      id
      SportId
      DefenseRank
      NumberOfGamesPlayed
      OffenseRank
      PowerRank
      StrengthOfScheduleDefenseRank
      StrengthOfScheduleOffenseRank
      StrengthOfScheduleRank
      TeamName
      TotalDefense
      TotalDefenseRankingsOfOpponents
      TotalMoneyLine
      TotalOffense
      TotalOffenseRankingsOfOpponents
      TotalOverUnder
      TotalPointSpread
      TotalPowerRankingOfOpponents
      LastUpdated      
    }
  }
`;
export const listCurrentPowerRankings = /* GraphQL */ `
  query ListCurrentPowerRankings(
    $filter: ModelCurrentPowerRankingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurrentPowerRankings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        SportId
        DefenseRank
        NumberOfGamesPlayed
        OffenseRank
        PowerRank
        StrengthOfScheduleDefenseRank
        StrengthOfScheduleOffenseRank
        StrengthOfScheduleRank
        TeamName
        TotalDefense
        TotalDefenseRankingsOfOpponents
        TotalMoneyLine
        TotalOffense
        TotalOffenseRankingsOfOpponents
        TotalOverUnder
        TotalPointSpread
        TotalPowerRankingOfOpponents
        LastUpdated        
      }
      nextToken
    }
  }
`;
export const getReportStatistics = /* GraphQL */ `
  query GetReportStatistics($id: ID!) {
    getReportStatistics(id: $id) {
      id
      SportId
      ReportName
      Season
      Games
      Wins
      Losses
      AvgWinPnl
      AvgLossPnl
      MoneyLineFilter
      WinPercentage
      LossPercentage
      NetWinPnl
      NetLossPnl
      LastUpdated   
      RecentRecord   
    }
  }
`;
export const listReportStatistics = /* GraphQL */ `
  query ListReportStatistics(
    $filter: ModelReportStatisticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportStatistics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        SportId
        ReportName
        Season
        Games
        Wins
        Losses
        AvgWinPnl
        AvgLossPnl
        MoneyLineFilter
        WinPercentage
        LossPercentage
        NetWinPnl
        NetLossPnl
        LastUpdated        
        RecentRecord
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      Name
      SqlTeamId
      SportId
      abbreviation
      logoFileName      
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        SqlTeamId
        SportId
        abbreviation
        logoFileName        
      }
      nextToken
    }
  }
`;
export const getNote = /* GraphQL */ `
  query GetNote($id: ID!) {
    getNote(id: $id) {
      id
      name
      description
      image
      createdAt
      updatedAt
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJoshTestNote = /* GraphQL */ `
  query GetJoshTestNote($id: ID!) {
    getJoshTestNote(id: $id) {
      id
      name
      description
      image
      createdAt
      updatedAt
    }
  }
`;
export const listJoshTestNotes = /* GraphQL */ `
  query ListJoshTestNotes(
    $filter: ModelJoshTestNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJoshTestNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
