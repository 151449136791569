import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {Consumer} from "./Context";
import { Dropdown, Option } from "../pages/oldstuff/Dropdown";
//import TeamsList from './TeamsList.jsx';
import ReportStatisticsList from './ReportStatisticsList.jsx';
import './Component.css';

import {
  //Button,
  Flex,
  Heading,
  //Image,
  //Text,
  //TextField,
  //View,
  //withAuthenticator,
} from '@aws-amplify/ui-react';

const Reports = () => {

  function handleLeagueSelect(event) {
    //console.log("Changed select box...");
    //console.log("League target value: " + event.target.value); // + this.sportId);
    setLeagueValue(event.target.value);
    setLastChangedValue(event.target.value);
  }
  
  function handleBetTypeSelect(event) {  
    //console.log("Changed select box...");
    setBetTypeValue(event.target.value);  
    setLastChangedValue(event.target.value);  
  }

  function handleMoneyLineSelect(event) {
    //console.log("Changed select box...");  
    setMoneyLineValue(event.target.value);  
    setLastChangedValue(event.target.value);
  }

  function handleSortSelect(event) {
    //console.log("Changed select box...");  
    setSortValue(event.target.value);  
    setLastChangedValue(event.target.value);
  }

  function handleSortTypeSelect(event) {
    //console.log("Changed select box...");  
    setSortTypeValue(event.target.value);  
    setLastChangedValue(event.target.value);
  }

  /*
  useEffect(() => {
    // no dependencies array,
    // Runs AFTER EVERY render
    console.log("Rendering Teams class...");
  })
*/
  //renders only on load
  useEffect(() => {   
    if (searchParams.get("sport")) {
      setLeagueValue(searchParams.get("sport").toUpperCase());
      setLastChangedValue(searchParams.get("sport").toUpperCase());
    }
    //console.log("Just rendered Teams class for first time");
  }, []); 

 //how we use state in function
  const [leagueValue, setLeagueValue] = useState("NFL");
  const [betTypeValue, setBetTypeValue] = useState("All");
  const [moneyLineValue, setMoneyLineValue] = useState("0");
  const [sortValue, setSortValue] = useState("Net Win Pnl $");
  const [sortTypeValue, setSortTypeValue] = useState("Descending");

  const [lastChangedValue, setLastChangedValue] = useState("NFL");
  let [searchParams, setSearchParams] = useSearchParams();

  function checkIfGoodLeague() {
    switch (leagueValue) {
    case "MLB":
        return true
    case "NBA":
        return true
    case "NFL":
        return true
    default:
        return false;        
    }
  }

  function BuildBetResultsPage() {
    return (
      <>
        <Heading className="headerReportLable" level={1}>Current Season {leagueValue} Betting Results</Heading> 
        <br></br>
        <Flex           
          direction="row"
          justifyContent="center"
          alignItems="center"
        >      
          <table className="bet-results-filter-selection-tables">
            <tr>
              <td>
                <div id='filterDivText' style={{ margin: 0 }}><span>Filter by Bet Type:&nbsp;&nbsp;</span></div>          
              </td>
              <td>
                <div  id='filterDivSelect' backgroundColor="green">
                <select style={{width: 200, height: 40, textAlign: "center"}} onChange={handleBetTypeSelect} value={betTypeValue} action="/reports">                     
                  <option>All</option>
                  <option>PointSpread</option>
                  <option>MoneyLine</option>
                  <option>OverUnder</option>                                   
                </select>
                </div>        
              </td>
            </tr>        
            <tr>        
              <td>
                <div id='filterDivText' style={{ margin: 0 }}><span>Filter by Money Line:&nbsp;&nbsp;</span></div>       
              </td>
              <td>   
                <div  id='filterDivSelect'>
                  <select style={{width: 200, height: 40, textAlign: "center"}} onChange={handleMoneyLineSelect} value={moneyLineValue} action="/reports">                     
                    <option>0</option>
                    <option>+150</option>
                    <option>+200</option>
                    <option>+300</option>                                   
                    <option>+500</option>
                    <option>-150</option>
                    <option>-200</option>
                    <option>-300</option>                                   
                    <option>-500</option>
                  </select>
                </div>
              </td>
            </tr>  
            <tr>
              <td>
                <div id='filterDivText' style={{ margin: 0 }}><span>Sort by: &nbsp;&nbsp; </span></div> 
              </td>
              <td>
                <div  id='filterDivSelect'>
                  <select style={{width: 200, height: 40, textAlign: "center"}} onChange={handleSortSelect} value={sortValue} action="/reports">                     
                    <option>Net Win Pnl $</option>
                    <option>Net Loss Pnl $</option>
                    <option>Avg Win Pnl $</option>
                    <option>Avg Loss Pnl $</option>                                   
                    <option>Win %</option>
                    <option>Loss %</option>                 
                  </select>
                </div>        
              </td>
            </tr>     
            <tr>
              <td>
                <div id='filterDivText' style={{ margin: 0 }}><span>Sort Type by: &nbsp;&nbsp;</span></div>      
              </td>
              <td>
                <div  id='filterDivSelect'>
                  <select style={{width: 200, height: 40, textAlign: "center"}} onChange={handleSortTypeSelect} value={sortTypeValue} action="/reports">                     
                    <option>Descending</option> 
                    <option>Ascending</option>                                         
                  </select>
                </div>     
              </td>
            </tr> 
          </table>
        </Flex>             
        <ReportStatisticsList key={lastChangedValue} league={leagueValue} betTypeFilter={betTypeValue} moneyLineFilter={moneyLineValue} sortBy={sortValue} sortByType={sortTypeValue} />
      </>
    )
  }

  return (
   
    <Consumer>
    { context => {

      return (
        <div>     
        <Flex           
              direction="row"
              justifyContent="center"
              alignItems="center"
            >         
          <div className="pageDiv">             {/*bet-results-PageDiv*/}
            <br></br>    
            {checkIfGoodLeague() ? 
                (<BuildBetResultsPage />) : (
                <Heading className="headerReportLable" level={1}>Whoops select a league from above.</Heading> )              
              }  
          </div>  
          </Flex>                
        </div>
        
      );
    }}
    </Consumer>
  );
};

export default Reports;