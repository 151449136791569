import React from "react";
//import DropdownList from "react-widgets/DropdownList";
//import "react-widgets/styles.css";
import "./Home.css";

//import '../components/Component.css';

import {    
    Flex,
  } from '@aws-amplify/ui-react';

export default function Home() {
    return (<>     
        <br/>
        <div className="pageDiv">
            <Flex           
                direction="row"
                justifyContent="center"
                alignItems="center"
                >             
                    <div className="pageDivImage"> 
                    <img id="imageLogo" src={require('../images/betlytics_logo.jpg')} alt="betlytics"></img>                    
                    </div> 
            </Flex>
            <br/>
            <Flex           
                direction="row"
                justifyContent="center"
                alignItems="center"
                >   
                    <span><span className="disclaimer"><b>Disclaimer</b></span><br/><br/>
                        Dear Betlytics readers and subscribers,<br/><br/>
                        Betlytics, is not an online gambling operator, or a gambling site of any kind. We solely display sports betting analytics and historical betting performances for entertainment purposes only. We do not take bets, place bets, or receive affiliate revenues from companies that do. We have no financial interest in our readers or subscribers placing wagers.
                        Betlytics can not be held responsible for the decisions of our readers or subscribers when off our site. By visiting our website you confirm that you do not hold Betlytics responsible for your gambling decisions or any losses that may follow from that personal decision and activity when off our site.
                        We do not conduct or promote gambling.  If you believe you have a problem with gambling, we highly encourage you to seek support from organizations such as <a href="https://www.gamblersanonymous.org/ga/">GamblersAnoymous</a> or <a href="https://www.1800gambler.net/">1-800-GAMBLER</a>.
                        <br/><br/>
                        We exist to provide interesting statistical observations to our customers, in hopes, they feel more informed when chatting about sports and making their own decisions.
                        <br/><br/>
                        Sincerely,
                        <br/><br/>
                        Betlytics and Purview Trading LLC
                    </span>
            </Flex>
       </div>                
    </>)
}