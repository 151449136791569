//import React, { useState, useEffect } from "react";
import React, { useContext, useState, useEffect } from "react";
import { Provider, myFirstContext } from "./components/Context";

import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import "./PageTemplate.css";

import { Header} from "./SignIn/LogoHeader.jsx";
import { Footer } from "./SignIn/Footer.jsx";
import { SignInHeader } from "./SignIn/SignInHeader.jsx";
import { SignInFooter } from "./SignIn/SignInFooter.jsx";
import "./SignIn/styles.css";

//for routing pages
import MenuHeader from "./MenuHeader.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";

//import About from "./pages/About.jsx";
//import Notes from "./components/Notes.jsx";
import Reports from "./components/Reports.jsx";
import ChartSample from "./components/ChartSample.jsx";
import Rankings from "./components/Rankings.jsx";
import Streaks from "./components/Streaks.jsx";

//import Pricing from "./pages/Pricing";
import Sidebar from "./SidebarMenu/Sidebar";

//import PageTemplate from "./pages/oldstuff/PageTemplate";

import {
  View,
  withAuthenticator,
} from '@aws-amplify/ui-react';

//import Sidebar from "./SidebarMenu/Sidebar";

//export const ColorContext = createContext();

function App({ signOut }) {

const [sport, setSport] = useState("ResetState");
//renders only on load
useEffect(() => {   
  //console.log("Just rendered Teams class for first time");
  console.log("App.js just rendered correctly");
}, []); 

  return (
    <Provider value={{
      signOut: signOut ,
      //[sport, setSport]
       sport, setSport 
     
        }}>
      <View className="App">          
        <Router>
          <div id='maindiv'>       
              <Sidebar />
              <div id='rightDiv'>
                  <MenuHeader />
                  <div id='page'>
               
                    <Routes>      
                      <Route path="/rankings" element={<Rankings />} /> 
                   
                      <Route path="/" element={<Home />} />
                      <Route path="/lab" exact element={<ChartSample />} />                   
                      <Route path="/results" element={<Reports />} /> 
                      
                      <Route path="/streaks" element={<Streaks />} />     
                      <Route path="*" element={<h1>Page not found</h1>} /> 
                  
                    </Routes>            
                  </div>
              </div>
          </div>  
          {/*<Sidebar />*/}
          {/*<MenuHeader />*/}
          
        </Router>
      </View>
     
    </Provider>
  );

}

//export default App;

export default withAuthenticator(App, {
  components: {
    Header,
    SignIn: {
      //Header: SignInHeader,
      Footer: SignInFooter
    },
    Footer
  }
});
//export default withAuthenticator(App);
//export default withAuthenticator(App, {  
  //socialProviders: ['apple']
//})
