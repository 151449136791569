import { Icon } from '@aws-amplify/ui-react';
import React from 'react';
import "../App.css";
import HomeIcon from '@mui/icons-material/Home';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';

export const SidebarData = [
    {
        title: "Home",
        icon: <HomeIcon />,
        link: "/"
    },
    {
        title: "Betting Results",
        icon: <AttachMoneyIcon />,
        link: "/results"
    },
    {
        title: "Team Rankings",
        icon: <LeaderboardIcon />,
        link: "/rankings"
    },
    {
        title: "Streaks",
        icon: <LocalFireDepartmentIcon />,
        link: "/streaks"
    },
    {
        title: "Lab",
        icon: <SsidChartIcon />,
        link: "/lab"
    }
]

