import React, { useState, useEffect, useMemo } from "react";
import  { useTable }  from "react-table";

//import 'bootstrap/dist/css/bootstrap.css';
import { API } from 'aws-amplify';

import { listCurrentPowerRankings, listCurrentTeamsBetStats} from "../graphql/queries";

import {
    Flex,
    //Heading,
    //Text,
    View,
    //withAuthenticator,
  } from '@aws-amplify/ui-react';

const RankingsTable = (props) => {
    const [sportId, setSportId] = useState(0);
    const [powerRankings, setPowerRankings] = useState([]);
    const [lastUpdated, setLastUpdate] = useState("n/a");
    const [betStats, setBetStats] = useState([]);

    const [testJosh, setTestJosh] = useState(0);

    useEffect(() => {
        // no dependencies array,
        // Runs AFTER EVERY render   
    })

    //renders only on load
    useEffect(() => {  
        //console.log("Rendering ReportsStatisticsList class for first time...");
        if (props.league !== "") {            
            //console.log("building team bet stats"); 
            
            const fetchData = async () => {           
                const betStatsData = await fetchCurrentTeamsBetStats();
                //console.log("bet stats count after build: ", betStatsData.length);
                //console.log("bulding power rankings");
                const data2 = await fetchCurrentPowerRankings(betStatsData);
                //console.log("power rankings count after build: ", data2.length);
                //console.log("finished getting power data");
            }

            fetchData();          
        }
    }, []); 

    function getSportId() {
        
        switch (props.league) {
        case "MLB":
            return 0
        case "NBA":
            return 1
        case "NFL":
            return 4
        default:
            return -1;        
        }
    }

    function formatUTC(lastUpdated) {

        //console.log("Last updated: ", lastUpdated);
        const date = new Date(lastUpdated);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };
        const localTime = date.toLocaleString('en-US', options);

        return localTime; 
    }

    function getTeamBetStats(teamId, betStatsData) {    
        var teamBetStats = [];

        var filteredBetStat = betStatsData.filter(team => team.TeamId === teamId);
        
        filteredBetStat.forEach(
            function(d){            
                
                teamBetStats  = d;

                return;
            }        
        )

        return teamBetStats;
    }

    function getTeamRecord(teamBetStats) {    
        var record = "n/a";

        if (props.rankingType.indexOf('Over Under') !== -1) {
            console.log("got here over under");      
            record = (teamBetStats.OverUnderHomeWins + teamBetStats.OverUnderAwayWins) + "-" + (teamBetStats.OverUnderHomeLosses + teamBetStats.OverUnderAwayLosses) + "-" + (teamBetStats.OverUnderHomeTies + teamBetStats.OverUnderAwayTies);  
        }
        else if (props.rankingType.indexOf('Point Spread') !== -1) {      
            record = (teamBetStats.PointSpreadHomeWins + teamBetStats.PointSpreadAwayWins) + "-" + (teamBetStats.PointSpreadHomeLosses + teamBetStats.PointSpreadAwayLosses) + "-" + (teamBetStats.PointSpreadHomeTies + teamBetStats.PointSpreadAwayTies);  
        }
        else {
            record = (teamBetStats.HomeWins + teamBetStats.AwayWins) + "-" + (teamBetStats.HomeLosses + teamBetStats.AwayLosses) + "-" + (teamBetStats.HomeTies + teamBetStats.AwayTies);            
        }

        return record;
    }

    async function fetchCurrentTeamsBetStats() {
        //console.log("we got here...");       
        const apiData = await API.graphql({ query: listCurrentTeamsBetStats, variables: { limit: 2000} });
        //console.log("we got here part2...");
        var betStatsFromAPI = apiData.data.listCurrentTeamsBetStats.items;
        var filteredBetStatsFromAPI = [];
        var lastUpdated = "n/a";

        let sportId = getSportId();

        betStatsFromAPI = betStatsFromAPI.filter(team => team._deleted !== true && team.SportId === sportId);
        
        betStatsFromAPI.forEach(
            function(d){
                //console.log(d);
                filteredBetStatsFromAPI.push(d);      
            }        
        )
                    
        setBetStats(filteredBetStatsFromAPI);      
    
        return filteredBetStatsFromAPI;
    };

    async function fetchCurrentPowerRankings(betStatsData) {        
        const apiData = await API.graphql({ query: listCurrentPowerRankings, variables: { limit: 2000} });
        var powerRankingsFromAPI = apiData.data.listCurrentPowerRankings.items;
        var filteredPowerRankingsFromAPI = [];
        var lastUpdated = "n/a";

        let sportId = getSportId();

        powerRankingsFromAPI = powerRankingsFromAPI.filter(team => team._deleted !== true && team.SportId === sportId);
        
        powerRankingsFromAPI.forEach(
            function(d){    
                if (lastUpdated.indexOf('n/a') !== -1) {
                    lastUpdated = formatUTC(d.LastUpdated);
                    setLastUpdate(lastUpdated);
                }
                var teamId = parseInt(d.id);            
                d.BetStats = getTeamBetStats(teamId, betStatsData);
                d.Record = getTeamRecord(d.BetStats);//"this is a test";
                filteredPowerRankingsFromAPI.push(d);     
                //console.log(d); 
            }        
        )

        if (props.rankingType.indexOf('Power') !== -1) {      
            filteredPowerRankingsFromAPI = filteredPowerRankingsFromAPI.sort((a, b) => (a.PowerRank > b.PowerRank) ? 1 : -1);
        }
        else if (props.rankingType.indexOf('Offensive') !== -1) {      
            filteredPowerRankingsFromAPI = filteredPowerRankingsFromAPI.sort((a, b) => (a.OffenseRank > b.OffenseRank) ? 1 : -1);
        }
        else if (props.rankingType.indexOf('Defensive') !== -1) {      
            filteredPowerRankingsFromAPI = filteredPowerRankingsFromAPI.sort((a, b) => (a.DefenseRank > b.DefenseRank) ? 1 : -1);
        }
        else if (props.rankingType.indexOf('Strength of Schedule') !== -1) {      
            filteredPowerRankingsFromAPI = filteredPowerRankingsFromAPI.sort((a, b) => (a.StrengthOfScheduleRank > b.StrengthOfScheduleRank) ? 1 : -1);
        }
        else if (props.rankingType.indexOf('Over Under') !== -1) {      
            console.log("filtering over under");
            filteredPowerRankingsFromAPI = filteredPowerRankingsFromAPI.sort((a, b) => ((a.BetStats.OverUnderWins - b.BetStats.OverUnderLosses) < (b.BetStats.OverUnderWins - b.BetStats.OverUnderLosses)) ? 1 : -1);
        }
        else if (props.rankingType.indexOf('Point Spread') !== -1) {      
            filteredPowerRankingsFromAPI = filteredPowerRankingsFromAPI.sort((a, b) => (a.BetStats.PointSpreadWins - b.BetStats.PointSpreadLosses < b.BetStats.PointSpreadWins - b.BetStats.PointSpreadLosses) ? 1 : -1);
        }
        var ranking = 0;

        filteredPowerRankingsFromAPI.forEach(    
            function(d){                         
                ranking += 1;
                d.ItemId = ranking;               
            }        
        )

        setPowerRankings(filteredPowerRankingsFromAPI); 
        
        return filteredPowerRankingsFromAPI;
    };

    function stateChange(f){  
        setSportId(sportId + 1);
        //console.log("Current sport id: " + sportId);
    }


    const tableColumns = [
        {   
            Header : "Ranking",
            accessor : "ItemId"
        },
        {   
            Header : "Team Name",
            accessor : "TeamName"
        },
        {   
            Header : "Record",
            accessor : "Record"
        },
        {   
            Header : "Power",
            accessor : "PowerRank"
        },
        {   
            Header : "Offense",
            accessor : "OffenseRank"
        },
        {   
            Header : "Defense",
            accessor : "DefenseRank"
        },
        {   
            Header : "Strength of Schedule",
            accessor : "StrengthOfScheduleRank"
        },
    ];

    //use memo doesn't recalc on every re render
    const columns = useMemo(() => tableColumns, [])
    //const data = useMemo(() => streaks, [])
    const data = powerRankings

    const tableInstance = useTable({
        columns,
        data
    })

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance


    return (   
        <div class="table-responsive">
    
        <Flex           
        direction="row"
        justifyContent="center"
        alignItems="center"
    >
    <br></br>
    * Last Updated: {lastUpdated}    
    </Flex>
    <Flex           
        direction="colum"
        justifyContent="center"
        alignItems="center"
    >
    {/*} <div id="divTestBox">this is a test</div>*/}
    <div class="table-container rankings-table-container">
    
        <table {...getTableProps()} className="styled-table2">
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()} className="red">
                        {headerGroup.headers.map((column) => {
                            if ((column.Header.indexOf("Team Name") === -1) && (column.Header.indexOf("Details") === -1) && (column.Header.indexOf("Strength of Schedule") === -1)) {
                                return <th id="tdCenter" {...column.getHeaderProps()}>{column.render('Header')}</th>
                            }
                            else if (column.Header.indexOf("Team Name") !== -1) {
                                return <th id="tdTeamName" {...column.getHeaderProps()}>{column.render('Header')}</th>
                            }
                            else if (column.Header.indexOf("Strength of Schedule") !== -1) {
                                return <th id="tdStrengthOfSchedule" {...column.getHeaderProps()}>{column.render('Header')}</th>
                            }
                            else {
                                return <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            }    
                            
                    })}                
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {

                            if ((cell.column.Header.indexOf("Team Name") === -1) && (cell.column.Header.indexOf("Details") === -1) && (cell.column.Header.indexOf("Strength of Schedule") === -1)) {
                                return <td id="tdCenter" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            }
                            else if (cell.column.Header.indexOf("Team Name") !== -1) {
                                return <td id="tdTeamName" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            }
                            else if (cell.column.Header.indexOf("Strength of Schedule") !== -1) {
                                return <td id="tdStrengthOfSchedule" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            }
                            else {
                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            }                              
                            })}
                        </tr>
                    )
                })}
                
            </tbody>
        </table>
       
        </div>
        </Flex>
        </div>
    );
}

export default RankingsTable;