import React, { useState, useEffect } from "react";
import {Consumer} from "./Context";

import RankingsTable from './RankingsTable.jsx';
import './Component.css';
//import './Test.css';


import { useSearchParams } from "react-router-dom";
import {
  //Button,
  Flex,
  Heading,
  //Image,
  //Text,
  //TextField,
  //View,
  //withAuthenticator,
} from '@aws-amplify/ui-react';

const Rankings = () => {

  function handleLeagueSelect(event) {
    //console.log("Changed select box...");
    //console.log("League target value: " + event.target.value); // + this.sportId);
    setLeagueValue(event.target.value);
    setLastChangedValue(event.target.value);
  }
  
  function handleRankingTypeSelect(event) {  
    //console.log("Changed select box...");
    setRankingTypeValue(event.target.value);  
    setLastChangedValue(event.target.value);  
  }

  /*
  useEffect(() => {
    // no dependencies array,
    // Runs AFTER EVERY render
    console.log("Rendering Teams class...");
  })
*/
  //renders only on load
  useEffect(() => {   
    //console.log("Just rendered Teams class for first time");
    if (searchParams.get("sport")) {
      setLeagueValue(searchParams.get("sport").toUpperCase());
      setLastChangedValue(searchParams.get("sport").toUpperCase());
    }
  }, []); 

 //how we use state in function
  const [leagueValue, setLeagueValue] = useState("NFL");
  const [rankingTypeValue, setRankingTypeValue] = useState("Power");

  const [lastChangedValue, setLastChangedValue] = useState("NFL");
  let [searchParams, setSearchParams] = useSearchParams();

  function checkIfGoodLeague() {
    switch (leagueValue) {
    case "MLB":
        return true
    case "NBA":
        return true
    case "NFL":
        return true
    default:
        return false;        
    }
  }

  function BuildRankingsPage() {
    return (
      <>
      
        <Heading className="headerReportLable" level={1}>Current Season {leagueValue} Team Rankings</Heading>
        <br></br>        
        <Flex           
          direction="row"
          justifyContent="center"
          alignItems="center"        
        >        
          <div id='filterDivText' style={{ margin: 0 }}><span>Ranking by Type:</span></div>          
          <div  id='filterDivSelect'>
          <select style={{width: 200, height: 40, textAlign: "center"}} onChange={handleRankingTypeSelect} value={rankingTypeValue} action="/rankings">                     
            <option>Power</option>
            <option>Offensive</option>
            <option>Defensive</option>
            <option>Strength of Schedule</option>                                                  
            <option>Over Under</option>
            <option>Point Spread</option>           
          </select>
          </div>        
        </Flex>   
      
        <RankingsTable key={lastChangedValue} league={leagueValue} rankingType={rankingTypeValue}  />
      </>
    );

  }

  return (
   
    <Consumer>
    { context => {

      return (
        <div class="table-responsive">     
        
          <Flex           
              direction="row"
              justifyContent="left"
              alignItems="left"
            >                         
            <div className="pageDiv">         
              <br></br>    
            
              {checkIfGoodLeague() ? 
                (<BuildRankingsPage />) : (
                <Heading className="headerReportLable" level={1}>Whoops select a league from above.</Heading> )              
              }            
            </div> 
          </Flex>         
          
        </div>        
      );
    }}
    </Consumer>
  );
};

       
export default Rankings;