//import * as React from "react";
import React, { useState, useEffect, useMemo, useContext } from "react";
import "./MenuHeader.css";
import { Link, useMatch, useResolvedPath, useSearchParams } from "react-router-dom";

import { Consumer,myFirstContext } from "./components/Context";

import {
  Button,
  /*Card,
  Flex,
  Heading,
  Image,
  Text,
  TextField,
  View,*/
  withAuthenticator,
} from '@aws-amplify/ui-react';
import { buttonBaseClasses } from "@mui/material";

//export default function MenuHeader() {
const MenuHeader = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { sport, setSport } = useContext(myFirstContext);
  const [leagueValue, setLeagueValue] = useState("NFL");

  function testQueryString(e) {
    
    let updatedSearchParams = new URLSearchParams(searchParams.toString());
    var buttonId = e.target.id;

    e.target.className = "leagueButtonActive";

    if (buttonId.indexOf("Nfl") !== -1) {
      setSport("NFL");
      updatedSearchParams.set('sport', 'nfl');
      
      var testButton = document.getElementById("buttonNfl");
      testButton.className = "leagueButtonActive";
      testButton = document.getElementById("buttonMlb");
      testButton.className = "leagueButton";
      testButton = document.getElementById("buttonNba");
      testButton.className = "leagueButton";
    }
    else if (buttonId.indexOf("Nba") !== -1) {
      setSport("NBA");
      updatedSearchParams.set('sport', 'nba');

      var testButton = document.getElementById("buttonNba");
      testButton.className = "leagueButtonActive";
      testButton = document.getElementById("buttonMlb");
      testButton.className = "leagueButton";
      testButton = document.getElementById("buttonNfl");
      testButton.className = "leagueButton";
    }
    else {
      setSport("MLB");
      updatedSearchParams.set('sport', 'mlb');

      var testButton = document.getElementById("buttonMlb");
      testButton.className = "leagueButtonActive";
      testButton = document.getElementById("buttonNba");
      testButton.className = "leagueButton";
      testButton = document.getElementById("buttonNfl");
      testButton.className = "leagueButton";
    }
    /*
    props.history.push({
      pathname: '/rankings',
      search: "?" + new URLSearchParams({sport: "nba"}).toString()
    })*/
   console.log("here comes the event");
   console.log(e.target.id);

    //console("this button was clicked: " + event.srcElement.id);

    setSearchParams(updatedSearchParams.toString());

    window.location.reload(false);
  }

  function checkIfSelected(e) {
    return "true";
  }
  /*
  function logLeagueChange(context, newSport) {
    context.sport = newSport;
  
    console.log("Here is the new sport: " + context.sport);
  }
*/
useEffect(() => {   
   
  //console.log("about to read props.");
  //console.log(props);
  //console.log(searchParams);
  //console.log("sport: " + searchParams.get("sport"));
  if (searchParams.get("sport")) {
    var currentSport = searchParams.get("sport");
    setLeagueValue(searchParams.get("sport").toUpperCase());

    if (currentSport.indexOf("nfl") !== -1) {
      var testButton = document.getElementById("buttonNfl");
      testButton.className = "leagueButtonActive";
      testButton = document.getElementById("buttonNba");
      testButton.className = "leagueButton";
      testButton = document.getElementById("buttonMlb");
      testButton.className = "leagueButton";
    }
    else if (currentSport.indexOf("nba") !== -1) {
      var testButton = document.getElementById("buttonNba");
      testButton.className = "leagueButtonActive";
      testButton = document.getElementById("buttonNfl");
      testButton.className = "leagueButton";
      testButton = document.getElementById("buttonMlb");
      testButton.className = "leagueButton";
    }
    else { //mlb
      console.log("chose mlb...");
      var testButton = document.getElementById("buttonMlb");
      testButton.className = "leagueButtonActive";
      testButton = document.getElementById("buttonNba");
      testButton.className = "leagueButton";
      testButton = document.getElementById("buttonNfl");
      testButton.className = "leagueButton";
    }
    //setLastChangedValue(searchParams.get("sport").toUpperCase());
  }
  else { //default to nba for not
    var testButton = document.getElementById("buttonNba");
    testButton.className = "leagueButtonActive";
    testButton = document.getElementById("buttonNfl");
    testButton.className = "leagueButton";

    setSport("MLB");
    let updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('sport', 'mlb');
    setSearchParams(updatedSearchParams.toString());

    window.location.reload(false);
  }
  //console.log("Just rendered Teams class for first time");
}, []); 

  return (
    <Consumer>
    { context => {

      return (
        <div className="divHeaderMenuWrapper">
          
            <nav className="nav">
            {/*
              <div className="divNav">
              <Link to="/" className="site-title"><span class="text-color">bet</span>lytics</Link> 
              </div>       
              */}
              {/*<span class="text-color">context: {context.sport}</span> */}
              <ul>
              {/* className="leagueButton" */}   
              <div className="divNav"><span className="text-color">Choose a League:</span></div>
              <button id="buttonMlb" active="true" className="leagueButton" onClick={testQueryString}>MLB</button>
              <button id="buttonNfl" className="leagueButton" onClick={testQueryString}>NFL</button>
              <button id="buttonNba" className="leagueButton" onClick={testQueryString}>NBA</button>
              {/*
              <button id="buttonNfl" className="leagueButton" onClick={testQueryString}>NFL</button>
              <button id="buttonNba" className="leagueButton" onClick={testQueryString}>NBA</button>
              */}
              </ul>              
                {/*
              <ul>               
                <CustomLink to={"/results"}>Betting Results</CustomLink>
                <CustomLink to={"/rankings"}>Team Rankings</CustomLink>
                <CustomLink to={"/streaks"}>Streaks</CustomLink>
                <CustomLink to={"/charts"}>Charts</CustomLink>
              </ul>
              */}
              <div className="divNav">             
                <Button width={120} onClick={context.signOut}>Sign Out</Button>              
              </div>
            
            </nav>
        
        </div>
        );
     }}
    </Consumer>
  );
}

export default MenuHeader;



function CustomLink({to, children, ...props}) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({path: resolvedPath.pathname, end: true})
  
  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>{children}</Link> 
    </li>
  );
}